<script setup lang="ts">
import { SidebarLoader } from '@/05-Features/SidebarLoader'
import fileLoader, { FileLoaderType } from '@/06-Entities/File/model/FileLoader'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { computed, Ref, ref, watch } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { IAddService, IAddServicesList, IInvoice } from '@/entityes/invoice/invoice.type'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import img from '@/07-Shared/ui/ImgDescriber'
import GuruLogo from '@/assets/guru_logo.svg'
import { ElMessageBox, ElNotification } from 'element-plus'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { SetUp, SuccessFilled, UploadFilled } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
import { FileFromServerItem, transformFileFromServer } from '@/06-Entities/File'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'

interface IProps {
  invoiceId: string | number
  goodId: string | number
}
const props = defineProps<IProps>()
const { getAdditionalServices, updateAdditionalService } = useInvoiceRepository()
const { colors, isDark } = useColors()
const showLoaderSideBar = ref(false)
const deductionServices: Ref<IAddService[] | undefined> = ref(undefined)
const { incomesDeductionsDescribers: describer, refunds } = AddIncomesRefundsDescriber()
const approvedDeductionTags = refunds.items.flatMap((item) => item.refunds).map((refund) => refund.tag)
const renderDeductionServices = computed(() => {
  return deductionServices.value?.filter((service) => approvedDeductionTags.includes(service.service_type))
})
const loading = ref(false)
const loaderConfig = ref<ILoaderDescriber | null>(null)
const loader = ref<FileLoaderType | undefined>(undefined)
const service = ref<IAddService | undefined>(undefined)
const stepper = useStepper()
const { getInvoice } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()
const hasFiles = computed(() => {
  return renderDeductionServices.value?.some((item) => item.files.length > 0)
})
const { switchDeclarationStep } = useDeclarationRepository()
function initLoader(type: string, open: boolean) {
  const getName = describer.find((i) => i.tag === type)?.name
  loaderConfig.value = {
    title: `${getName}`,
    text: `Нажмите или перетащите файлы ${getName}`,
    serverTag: 'service_files',
    name: 'AdditionalServiceFiles',
    fileFormat: /.*\.(csv|xlsx|xls|pdf|xml|jpeg|jpg|png|json|docx|doc|XLSX|PDF|CSV|XML|JPEG|JPG|PNG)$/,
    img: img.csv,
    logo: GuruLogo,
  } as ILoaderDescriber
  loader.value = fileLoader(loaderConfig.value as ILoaderDescriber)
  service.value = deductionServices.value?.find((i) => i.service_type === type)
  showLoaderSideBar.value = open
}
function saveFiles() {
  loading.value = true
  if (!!loader.value) {
    const body = {
      id: service.value?.id,
      service_type: service.value?.service_type,
      incomes_group: 0,
      service_files: {
        additional_service_files: {
          files: [...loader.value?.localFiles.files],
          file_names: [...loader.value?.localFiles.file_names],
          delete_files: [...loader.value.deleteFiles],
        },
      },
    }
    updateAdditionalService(props.invoiceId, body)
      .then(() => {
        getServices()
        ElNotification({
          title: 'Успешно',
          message: `обновлены данные для ${describer.find((i) => i.tag === service.value?.service_type)?.name}`,
          type: 'success',
        })
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `обновления данных для ${
            describer.find((i) => i.tag === service.value?.service_type)?.name
          } | ${err}`,
          type: 'error',
        })
      })
      .finally(() => (loading.value = false))
  }
}
function downLoadFile(file: FileFromServer) {
  if (!!service.value) {
    const params = {
      invoiceId: props.invoiceId,
      serviceId: service.value.id,
      file,
    }
    loader.value?.downloadAdditionalServiceFile(params)
  }
}
function getServices() {
  getAdditionalServices(props.invoiceId).then((res: IAddServicesList) => {
    deductionServices.value = res?.additional_services
  })
}
function deleteFilesFromCard(fileId: number) {
  loader.value?.onAddDeleteFile(fileId)
  const fileName = service.value?.files.find((file) => file.id === fileId)?.name
  ElMessageBox.confirm(`Вы хотите удалить файл ${fileName}`, 'Внимание!', {
    confirmButtonText: 'Удалить файл',
    cancelButtonText: 'Отменить',
    type: 'warning',
    buttonSize: 'small',
  } as any)
    .then(() => {
      saveFiles()
    })
    .catch(() => {})
}
function spliceStep() {
  loading.value = true
  switchDeclarationStep(props.goodId, { step: ['Deduction'] })
    .then(() => {
      getInvoice(props.invoiceId).then((res) => {
        initAsync(res).then((res) => {
          stepper.selectStep(res)
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса перехода на следующий шаг ${err}`,
      })
    })
    .finally(() => (loading.value = false))
}

watch(loader, () => {
  if (!!service.value) {
    loader.value?.setFilesFromServer(service.value?.files.map((i) => transformFileFromServer(i)))
  }
})

getServices()
</script>

<template>
  <div class="step-box mt-8">
    <div class="p-4 mb-8">
      <div class="grid grid-rows-* grid-cols-3 gap-4" v-if="!!deductionServices" v-loading="loading">
        <div v-for="service in renderDeductionServices" :key="service.id">
          <div class="border border-2 dark:bg-dark-dark_gray bg-gray p-4">
            <div class="flex items-center mb-8">
              <img
                class="mr-2"
                width="20px"
                :src="describer.find((i) => i.tag === service.service_type)?.icon"
                alt="icon"
              />
              <p class="mr-2">{{ describer.find((i) => i.tag === service.service_type)?.name }}</p>
            </div>
            <el-button
              size="small"
              v-if="service.files.length === 0"
              @click="initLoader(service.service_type, true)"
              type="primary"
              >Загрузить файлы</el-button
            >
            <div v-else class="flex justify-between items-center">
              <el-popover
                @show="initLoader(service.service_type, false)"
                placement="right"
                :width="400"
                trigger="click"
              >
                <template #reference>
                  <div class="flex items-center cursor-pointer hover:opacity-50">
                    <el-icon class="mr-1" :color="isDark ? colors.dark.ng_green : colors.light.ng_green">
                      <SuccessFilled />
                    </el-icon>
                    <p class="dark:text-dark-green text-light-green">Файлы загружены</p>
                  </div>
                </template>
                <div v-if="!!loader">
                  <file-from-server-item
                    :files="service.files.map((file) => transformFileFromServer(file))"
                    :no-delete="false"
                    @delete="deleteFilesFromCard"
                    @download="downLoadFile"
                  />
                </div>
              </el-popover>
              <el-button @click="initLoader(service.service_type, true)" :icon="SetUp" link type="primary" size="small">
                Изменить
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray p-4">
      <el-button
        :disabled="!hasFiles"
        :loading="loading"
        @click="spliceStep"
        :icon="UploadFilled"
        class="button-main"
        type="success"
      >
        Отправить данные
      </el-button>
    </div>
  </div>
  <div>
    <sidebar-loader
      v-if="loader"
      :loader-describer="loader"
      :show-instruction="false"
      v-model:show-sidebar="showLoaderSideBar"
      @readed-file="loader.onUpdateFilesEvent"
      @delete-local-file="loader.onRemoveLocalFile"
      @delete-server-file="loader.onAddDeleteFile"
      @undo-delete-file="loader.undoDeleteFiles"
      @save-files="saveFiles"
      @download-server-file="downLoadFile"
      show-server-files
      show-delete-button
    />
  </div>
</template>

<style scoped lang="sass"></style>
