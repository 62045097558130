import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/shop_icon.svg'
import _imports_1 from '@/assets/basket_icon.svg'


const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-wrap items-center justify-between mb-4"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "min-h-[70px] px-4 bg-gray border--top flex items-center justify-between" }
const _hoisted_8 = { class: "px-4 pt-4" }
const _hoisted_9 = { class: "sticky-basket" }
const _hoisted_10 = { class: "min-h-[70px] flex items-center p-4 bg-gray border--top justify-between" }
const _hoisted_11 = {
  key: 1,
  class: "p-4"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "flex justify-end" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

import { IInvoice, InvoiceTariffType } from '@/entityes/invoice/invoice.type'
import Tariffs from '@/04-Widgets/GoodsSelection/ui/Tariffs.vue'
import { computed, ComputedRef, reactive, ref, watch } from 'vue'
import useGoodsDescription, { IGood } from '@/describers/GoodsDescriber'
import GoodCard from '@/04-Widgets/GoodsSelection/ui/GoodCard.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import YearSelect from '@/04-Widgets/GoodsSelection/ui/YearSelect.vue'
import usePolitic from '@/use/usePolitic'
import Basket from '@/04-Widgets/GoodsSelection/ui/Basket.vue'
import { CreditCard, Discount } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import FinishPaymentDialog from '@/components/Admin/FinishPaymentDialog.vue'
import { ElNotification } from 'element-plus'
import { useRouter } from 'vue-router'
import useGtagManager from '@/use/useGtagManager'
import PromocodeForm from '@/04-Widgets/GoodsSelection/ui/PromocodeForm.vue'
import OTO from '@/04-Widgets/GoodsSelection/ui/OTO.vue'
import DiscountRequest from '@/components/DiscountRequest.vue'
import useTextFilters from '@/plugins/TextFilters'
import SplitWindow from '@/07-Shared/ui/SplitWindow.vue'
import { BrokerFileType } from '@/entityes/invoice/file/file.type'
import { IPaymentBody } from '@/entityes/invoice/invoice.type'

interface IProps {
  invoice: IInvoice
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentPage',
  props: {
    invoice: {}
  },
  setup(__props: any) {

const props = __props
const { isAdmin, isCustomer } = usePolitic()
const { goodSelect, getInvoice, surchargePayment } = useInvoiceRepository()
const { event } = useGtagManager()
const { goodsList } = useGoodsDescription()
const { numberSeparator } = useTextFilters()

const surcharge = computed(() => props.invoice?.surcharge || 0)
const tariff = ref(props.invoice?.tariff || 'base')
const goods = computed(() => {
  // TMP: temporary decision
  if (hasFFGlobal.value && tariff.value === 'minimal') {
    return goodsList.filter((j) => j.showGood && !['Cfr', 'cfr_pdf'].includes(j.name))
  } else {
    return goodsList.filter((i) => i.showGood)
  }
})
const baseYearsArray = [2018, 2019, 2020, 2021, 2022, 2023, 2024]
const years = reactive([...baseYearsArray])
const selectedYear = ref(props.invoice.year_to_calc)
const selectedCountry = ref('ru')
const brokerFiles = computed(() => {
  const files: Partial<Record<BrokerFileType, { id: number; name: string }[]>> | undefined = props.invoice?.broker_files
  if (!files) return []
  const keys = Object.keys(files) as BrokerFileType[]

  return keys.filter((key) => {
    const fileArray = files[key]
    return !!fileArray && fileArray.length > 0
  })
})
const total = ref(0)
const loading = ref(false)
const router = useRouter()
const discountRequest = ref<any | null>(null)
const basketEmpty = computed(() => goods.value.filter((i) => i.inBasket.value).length === 0)

const tinkoffPremiumLink = computed(() => props.invoice?.payments?.link)
const tinkoffPremiumInvoice = computed(() => props.invoice?.email === 'external_premium@tinkoff.tinkoff')

const hasBucket = computed(() => brokerFiles.value.includes('bucket_files'))
const hasFFGlobal = computed(() => brokerFiles.value.includes('freedom_finance_global_files'))
const hasDeclarationPdf = computed(() => goods.value.find((i) => i.name === 'declaration_pdf')?.inBasket.value)
const hasCfrPdf = computed(() => goods.value.find((i) => i.name === 'cfr_pdf')?.inBasket.value)
const hasTaxPlan = computed(() => goods.value.find((i) => i.name === 'tax_plan')?.inBasket.value)

const services = computed(() => ({
  ...props.invoice.pre_selected_add_incomes,
  ...(hasDeclarationPdf.value ? { declaration_pdf: 1 } : {}),
  ...(hasCfrPdf.value ? { cfr_pdf: 1 } : {}),
  ...(hasTaxPlan.value ? { tax_plan: 1 } : {}),
}))
const createBody: ComputedRef<IPaymentBody> = computed(() => {
  const goodsInBasket = goods.value.filter((i) => i.inBasket.value).map((i) => i.name)
  return {
    goods: goodsInBasket.filter((i) => ['Declaration', 'Cfr'].includes(i)),
    year_to_calc: Number(selectedYear.value),
    country: selectedCountry.value,
    price: total.value,
    tariff: tariff.value,
    services: goodsInBasket.includes('Declaration') ? services.value : {},
  }
})

const invoiceTotalPremiumTinkoff = computed(() => (props.invoice?.payments ? props.invoice?.payments.price : 0))

const totalText = computed(() => {
  if (surcharge.value > 0) {
    return 'Итого к доплате '
  } else if (hasBucket.value) {
    return 'Предоплата '
  } else {
    return 'Итого '
  }
})

function showDiscountRequest() {
  discountRequest.value.openModal()
}
function customerPayment(callbackConfig: { method: (link?: string) => Promise<void> }) {
  loading.value = true
  event('initiateCheckout')
  if (surcharge.value > 0) {
    surchargePayment(props.invoice.id)
      .then((res: { [key: string]: string }) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          callbackConfig?.method?.(res.link)
        }
      })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
        loading.value = false
      })
  } else {
    goodSelect(props.invoice.id, createBody.value)
      .then((res: Record<string, string>) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          // if (callbackConfig?.method) {
          callbackConfig?.method().then(() => {
            getInvoice(props.invoice.id)
            router.push({ name: 'invoice show' })
          })
          // }
        }
      })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
        loading.value = false
      })
  }
}
function getPaymentLink(callbackConfig: Record<string, (link: string) => void>) {
  goodSelect(props.invoice.id, createBody.value).then((res: Record<string, string>) => {
    callbackConfig?.method?.(res?.link)
  })
}
function selectTariff(val: InvoiceTariffType) {
  tariff.value = val
}
function setupPrices() {
  goodsList.forEach((item: IGood) => {
    const prices: any = props.invoice?.all_price
    if (!!props.invoice?.all_price && Object.keys(props.invoice?.all_price).length === 0) {
      item.price.value = 0
      item.basePrice.value = 0
    }
    if (surcharge.value > 0) {
      item.price.value = surcharge.value
    } else {
      if (props.invoice?.all_price) {
        const price = prices[selectedYear.value][tariff.value][item.priceTag]
        const basePrice = prices[selectedYear.value][tariff.value][item.priceBaseTag]
        if (price && basePrice) {
          item.price.value = price
          item.basePrice.value = basePrice
        }
      }
      if (item.name === 'tax_plan') {
        item.price.value = 0
      }
    }
  })
}
function mergeYears() {
  if (props.invoice.all_price) {
    const yearsKeys = Object.keys(props.invoice?.all_price).map((year) => parseInt(year, 10))
    years.splice(0, years.length, ...yearsKeys)
    if (years.includes(props.invoice.year_to_calc)) {
      selectedYear.value = props.invoice.year_to_calc
    } else {
      selectedYear.value = years[years.length - 1]
    }
  } else {
    selectedYear.value = 2024
  }
}
function changeYear(val: number) {
  selectedYear.value = val
}
function changeCountry(val: string) {
  selectedCountry.value = val
}
function mergeTotal(val: number) {
  total.value = val
}

mergeYears()
setupPrices()
watch([tariff, selectedYear], setupPrices)

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (surcharge.value > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("h1", { class: "mb-4" }, "Требуется доплата", -1),
          _createElementVNode("div", { class: "mb-4" }, [
            _createElementVNode("p", null, "Так как число операций выросло, то для продолжения оформления документов необходима доплата")
          ], -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "mr-3" }, "Шаг 3. Выберите товары и узнайте их стоимость", -1)),
            _createVNode(NgInvoiceVideo, {
              ref: "invoiceVideo",
              "show-button": true,
              "video-link": 'https://www.youtube.com/embed/8bocQ9Uhkik?si=U5G1gVBB4hn-xpIq'
            }, null, 512)
          ]),
          (props.invoice.oto_timer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(OTO, {
                  ototimer: props.invoice.oto_timer
                }, null, 8, ["ototimer"])
              ]))
            : _createCommentVNode("", true)
        ])),
    _withDirectives((_openBlock(), _createBlock(SplitWindow, null, {
      left: _withCtx(() => [
        (surcharge.value === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-center" }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "mr-4 dark-purple-icon",
                      src: _imports_0,
                      alt: "shop_icon"
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", null, "Товары")
                  ])
                ], -1)),
                _createVNode(YearSelect, {
                  onSelectYear: changeYear,
                  onSelectCountry: changeCountry,
                  "is-admin": _unref(isAdmin),
                  years: Object.keys(props.invoice.all_price).map((i) => Number(i)),
                  "year-to-calc": selectedYear.value
                }, null, 8, ["is-admin", "years", "year-to-calc"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(goods.value, (good) => {
                  return (_openBlock(), _createBlock(GoodCard, {
                    key: good.name,
                    good: good,
                    year: selectedYear.value
                  }, null, 8, ["good", "year"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center" }, [
              _createElementVNode("img", {
                class: "mr-4 dark-purple-icon",
                src: _imports_1,
                alt: "basket_icon"
              }),
              _createElementVNode("h3", null, "Стоимость услуг")
            ], -1)),
            _createElementVNode("div", null, [
              (!basketEmpty.value)
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 0,
                    content: "Перейти на страницу оплаты"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        onClick: customerPayment,
                        icon: _unref(CreditCard),
                        type: "success",
                        loading: loading.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" ₽ " + _toDisplayString(_unref(numberSeparator)(total.value, ' ')) + " " + _toDisplayString(totalText.value), 1)
                        ]),
                        _: 1
                      }, 8, ["icon", "loading"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          (basketEmpty.value)
            ? (_openBlock(), _createBlock(_component_el_empty, {
                key: 0,
                "image-size": 44,
                description: "Не выбрано ни одного товара"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (surcharge.value === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (surcharge.value === 0)
                        ? (_openBlock(), _createBlock(Tariffs, {
                            key: 0,
                            tariff: props.invoice.tariff,
                            "has-bucket": hasBucket.value,
                            "personal-price": props.invoice.all_price[selectedYear.value]?.personal?.personal || 0,
                            onOnChangeTariff: selectTariff
                          }, null, 8, ["tariff", "has-bucket", "personal-price"]))
                        : _createCommentVNode("", true),
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1))
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(Basket, {
                  goods: goods.value,
                  year: selectedYear.value,
                  "broker-files": brokerFiles.value,
                  "amount-operations": props.invoice.amount_operations.Declaration?.[selectedYear.value],
                  "add-selection": props.invoice.all_price[selectedYear.value][tariff.value].add_services,
                  "personal-price": tariff.value === 'personal' ? props.invoice.all_price[selectedYear.value][tariff.value].personal || 0 : 0,
                  tariff: tariff.value,
                  onInvoiceTotal: mergeTotal
                }, null, 8, ["goods", "year", "broker-files", "amount-operations", "add-selection", "personal-price", "tariff"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex mb-2", tariff.value === 'minimal' ? 'justify-end' : 'justify-between'])
                }, [
                  (surcharge.value === 0 && tariff.value !== 'minimal')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(PromocodeForm, {
                          "good-body": createBody.value,
                          "invoice-id": props.invoice.id,
                          promo: props.invoice.promo?.code,
                          class: "mb-2"
                        }, null, 8, ["good-body", "invoice-id", "promo"]),
                        _createVNode(_component_el_button, {
                          onClick: showDiscountRequest,
                          type: "primary",
                          icon: _unref(Discount),
                          link: ""
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("хочу промокод")
                          ])),
                          _: 1
                        }, 8, ["icon"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_14, [
                    (tinkoffPremiumInvoice.value)
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_15, "₽ " + _toDisplayString(invoiceTotalPremiumTinkoff.value), 1))
                      : (_openBlock(), _createElementBlock("h2", _hoisted_16, [
                          _createElementVNode("span", null, _toDisplayString(totalText.value), 1),
                          _createTextVNode("₽ " + _toDisplayString(surcharge.value > 0 ? _unref(numberSeparator)(surcharge.value, ' ') : _unref(numberSeparator)(total.value, ' ')), 1)
                        ]))
                  ])
                ], 2),
                (!_unref(isCustomer))
                  ? (_openBlock(), _createBlock(FinishPaymentDialog, {
                      key: 1,
                      id: props.invoice.id,
                      onSend: customerPayment,
                      onGetLink: getPaymentLink,
                      "tinkoff-link": tinkoffPremiumLink.value,
                      "is-tinkoff-premium": tinkoffPremiumInvoice.value,
                      "is-admin": _unref(isAdmin)
                    }, null, 8, ["id", "tinkoff-link", "is-tinkoff-premium", "is-admin"]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ]),
      _: 1
    })), [
      [_directive_loading, loading.value]
    ]),
    _createVNode(DiscountRequest, {
      id: props.invoice.id,
      ref_key: "discountRequest",
      ref: discountRequest
    }, null, 8, ["id"])
  ]))
}
}

})