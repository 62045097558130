import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = { class: "p-4 bg-gray flex items-center justify-between min-h-[70px]" }
const _hoisted_4 = { key: 0 }

import SplitWindow from '@/07-Shared/ui/SplitWindow.vue'
import { useLoaderDescriber } from '../model'
import { BrokersAndLoadFiles } from '@/05-Features/FileLoader'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { FileChangedView } from '@/05-Features/FileChangedView'
import { SidebarLoader } from '@/05-Features/SidebarLoader'
import { computed, ref, watch } from 'vue'
import { FileTagType } from '@/06-Entities/File'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { useSendFileRequest } from '../api/sendFile.request'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { UploadFilled } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'

interface IProps {
  invoiceId: number | string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadFileStepComponent',
  props: {
    invoiceId: {}
  },
  setup(__props: any) {

const props = __props
const { loaderDescriber, selectActiveLoader, activeLoader } = useLoaderDescriber()
const invoiceStore = useInvoiceStore()
const invoice = computed(() => invoiceStore.invoice)

watch(invoice, () => {
  if (!!invoiceStore.invoice) {
    loaderDescriber.mergeFile(invoiceStore.invoice.broker_files)
  }
})
if (!!invoiceStore.invoice) {
  loaderDescriber.mergeFile(invoiceStore.invoice.broker_files)
}
const showSidebar = ref(true)
function openModal(serverTag: FileTagType) {
  selectActiveLoader(serverTag)
  showSidebar.value = true
}
const { loadFile } = useSendFileRequest()
const stepper = useStepper()
const { initAsync } = useAsyncInvoiceReload()
function sendFiles() {
  loadFile(props.invoiceId, loaderDescriber.toJson())
    .then((invoice) => {
      invoiceStore.invoice = invoice
      initAsync(invoice).then((res) => {
        loaderDescriber.describers.forEach((loader) => {
          loader.localFiles.files.splice(0, loader.localFiles.files.length)
          loader.localFiles.file_names.splice(0, loader.localFiles.file_names.length)
        })
        stepper.selectStep(res)
      })
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `загрузки файлов. Попробуйте еще раз. ${err}`,
        type: 'error',
      })
    })
}
useInvoiceRepository()
  .getInvoice(props.invoiceId)
  .then((res) => {
    initAsync(res)
  })

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "mr-8" }, "Шаг 1. Загрузите отчеты брокеров", -1)),
      _createVNode(NgInvoiceVideo, {
        ref: "invoiceVideo",
        "show-button": true,
        "video-link": 'https://www.youtube.com/embed/2wq-EYaR5R0?si=sknCrVbJzS6JlskK'
      }, null, 512)
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-4" }, [
      _createElementVNode("p", { class: "mb-2" }, "Нажмите на название брокера и следуйте инструкции по правильному скачиванию отчета брокера."),
      _createElementVNode("p", null, "Наличие других доходов и вычетов можно указать на следующей странице.")
    ], -1)),
    _createVNode(SplitWindow, null, {
      left: _withCtx(() => [
        _createVNode(_unref(BrokersAndLoadFiles), {
          "invoice-id": props.invoiceId,
          "loader-describer": _unref(loaderDescriber),
          onDeleteFileFromServer: _unref(loaderDescriber).deleteFromServer,
          onSelectLoader: openModal
        }, null, 8, ["invoice-id", "loader-describer", "onDeleteFileFromServer"])
      ]),
      right: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Портфель", -1)),
          (_unref(loaderDescriber).hasLocalFiles.value || _unref(loaderDescriber).hasFileToDelete.value)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                class: "button-main",
                type: "success",
                loading: _unref(loadFile).indicator.value,
                onClick: sendFiles,
                icon: _unref(UploadFilled)
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Загрузить файлы ")
                ])),
                _: 1
              }, 8, ["loading", "icon"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_unref(FileChangedView), {
          describer: _unref(loaderDescriber),
          onRemoveDeletedFile: _unref(loaderDescriber).removeDeleteFile,
          onRemoveLocalFile: _unref(loaderDescriber).removeLocalFile
        }, null, 8, ["describer", "onRemoveDeletedFile", "onRemoveLocalFile"])
      ]),
      _: 1
    }),
    (_unref(activeLoader))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_unref(SidebarLoader), {
            "show-sidebar": showSidebar.value,
            "onUpdate:showSidebar": _cache[0] || (_cache[0] = ($event: any) => ((showSidebar).value = $event)),
            "loader-describer": _unref(activeLoader),
            "show-delete-button": true,
            onReadedFile: _unref(activeLoader).onUpdateFilesEvent,
            onDeleteLocalFile: _unref(activeLoader).onRemoveLocalFile,
            onDeleteServerFile: _unref(activeLoader).onAddDeleteFile
          }, null, 8, ["show-sidebar", "loader-describer", "onReadedFile", "onDeleteLocalFile", "onDeleteServerFile"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})