<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useIncomeCollection } from '../model/useIncomeCollection'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import usePolitic from '@/use/usePolitic'
import AddSaveButtonGroup from './AddSaveButtonGroup.vue'
import MessageBoxSelect from '@/07-Shared/ui/MessageBoxSelect.vue'
import { ElMessageBox, ElNotification } from 'element-plus'
import useWindowWidth from '@/use/useWindowWidth'
import { ValidationAddError } from '@/components/Invoice/Declaration/support/Errors'
import onBoardingDescriber from '@/describers/OnBoardingDescriber'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import AddIncomeCustomerUI from '@/04-Widgets/AdditionelIncome/ui/AddIncomeCustomerUI.vue'

interface IProps {
  invoiceId: string | number
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const goodId = computed(() => invoiceStore.invoice?.goods.Declaration || '')
const ItemsCollection = useIncomeCollection()
const onBoardingFields = onBoardingDescriber().onBoardingIncomeFields
function getItemsFromServer() {
  if (!invoiceStore.invoice) return
  ItemsCollection.getFromServer(goodId.value)
}
const situationList = computed(() => {
  return ItemsCollection.availableSituation.value.filter((i) => !['two_ndfl_source', 'standard_refund'].includes(i.tag))
})
const politics = usePolitic()
const { screenLessValue } = useWindowWidth()
function show() {
  messageBox.value?.show()
}
function sendToServer() {
  if (!goodId.value) return
  ItemsCollection.setTouch()
  ItemsCollection.sendToServer(goodId.value)
    .then(() => {
      ElNotification({
        title: 'Дополнительные доходы',
        message: 'успешно сохранены',
        type: 'success',
      })
      useInvoiceRepository()
        .getInvoice(props.invoiceId)
        .then((res) => {
          useAsyncInvoiceReload()
            .initAsync(res)
            .then(() => {
              getItemsFromServer()
            })
        })
    })
    .catch((err) => {
      if (err instanceof ValidationAddError) {
        ElMessageBox.alert(
          'Вы заполнили не все поля или некорректно ввели данные. Проверьте введенную информацию и повторите попытку.',
          'Ошибка',
          {
            confirmButtonText: 'Понятно',
            type: 'error',
          }
        )
      } else {
        console.error(err)
      }
    })
}

const messageBox = ref<InstanceType<typeof MessageBoxSelect> | null>(null)

function deleteUnsaveGroup(targetName: string) {
  const msg = `Вы хотите удалить все несохраненные ситуации ${
    ItemsCollection.describers.find((item) => item.tag === targetName)?.name
  }?
      Внесенные данные вернуть будет невозможно.`
  ElMessageBox.confirm(msg, {
    confirmButtonText: 'Да, хочу удалить',
    cancelButtonText: 'Нет, не хочу удалять',
    type: 'warning',
  }).then(() => {
    // wantSet.value = true
    ItemsCollection.deleteUnsaveBySituation(targetName)
  })
}
function deleteGroup(targetName: string) {
  const msg = `Вы хотите удалить все ситуации ${
    ItemsCollection.describers.find((item) => item.tag === targetName)?.name
  }`
  ElMessageBox.confirm(msg, {
    confirmButtonText: 'Да, хочу удалить',
    cancelButtonText: 'Нет, не хочу удалять',
    type: 'warning',
  }).then(() => {
    // wantSet.value = true
    ItemsCollection.deleteBySituation(targetName)
  })
}

function createNew(name: string) {
  if (
    name === 'two_ndfl_files' &&
    ItemsCollection.showItemDescibersWithHaveElements.value.some((item) => item.tag === 'two_ndfl_files')
  ) {
    ItemsCollection.activeSituation.value = 'two_ndfl_files'
  } else {
    ItemsCollection.createNew(name)
  }
}

watch(
  goodId,
  () => {
    useInvoiceRepository()
      .getInvoice(props.invoiceId)
      .then((res) => {
        useAsyncInvoiceReload()
          .initAsync(res)
          .then(() => {
            getItemsFromServer()
          })
      })
  },
  { immediate: true }
)
</script>

<template>
  <div class="container-list" v-if="!!invoiceStore.invoice">
    <component :is="ItemsCollection.meta.headerComponent" />
    <div v-if="politics.isCustomer.value">
      <AddIncomeCustomerUI :invoice-id="invoiceStore.invoice.id" :good-id="invoiceStore.invoice.goods.Declaration" />
    </div>
    <div v-if="!politics.isCustomer.value">
      <div class="flex items-center my-4">
        <AddSaveButtonGroup
          :want-save="ItemsCollection.edited.value"
          page="AddIncomes"
          :has-items="ItemsCollection.collection.length > 0"
          @add="show"
          @save="sendToServer"
          @skip="sendToServer"
        />
      </div>
      <el-tabs v-model="ItemsCollection.activeSituation.value">
        <el-tab-pane
          v-for="item in ItemsCollection.showItemDescibersWithHaveElements.value"
          :key="item.name"
          :label="item.name"
          :name="item.tag"
        >
          <div class="flex flex-col items-center justify-between my-2">
            <el-button-group
              v-if="!['standard_refund', 'two_ndfl_files', 'material_losses', 'two_ndfl_source'].includes(item.tag)"
              class="w-full mb-4"
            >
              <el-tooltip class="item" effect="dark" :content="`Добавить ситуацию ${item.name}`" placement="top-start">
                <el-button :icon="CirclePlusFilled" type="primary" @click="ItemsCollection.createNew(item.tag)">
                  Добавить
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="`Удалить вcе ситуации несохраненные из ${item.name}`"
                placement="top-start"
                v-if="
                  ItemsCollection.haveUnsaveInActiveSituation.value && politics.isAdmin.value && !screenLessValue(767)
                "
              >
                <el-button :icon="RemoveFilled" @click="deleteUnsaveGroup(item.tag)">
                  Удалить все несохраненные
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="`Удалить вcе ситуации из ${item.name}`"
                placement="top-start"
              >
                <el-button :icon="RemoveFilled" type="danger" @click="deleteGroup(item.tag)"> Удалить все </el-button>
              </el-tooltip>
            </el-button-group>
            <div v-for="item in ItemsCollection.itemsForActiveSituation.value" :key="item.localUniqueId" class="w-full">
              <div class="step-box--small my-2">
                <div :class="item.fieldWasEdited ? 'bg-gray' : ''" class="p-4">
                  <component
                    :is="item.getActiveComponent?.cardComponent"
                    :item="item"
                    :key="'item_card_' + item.localUniqueId"
                    @remove="
                      () => {
                        ItemsCollection.deleteById(item.localUniqueId)
                      }
                    "
                    @toggle-edit="
                      () => {
                        item.toggleEdit()
                      }
                    "
                    v-if="Object.keys(item).length > 0"
                  />
                </div>
                <div v-if="item.isEdit" class="step-box">
                  <div>
                    <component
                      :is="item.getActiveComponent?.component"
                      :key="'item_card#' + item.localUniqueId"
                      :item="item"
                      @update-item="item.onUpdateEvent"
                      :year-to-calc="invoiceStore.invoice?.year_to_calc"
                      :help-describer="item.getActiveComponent?.tag && onBoardingFields[item.getActiveComponent.tag]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <MessageBoxSelect
    ref="messageBox"
    title="Добавление ситуации"
    :value="situationList"
    value-tag="tag"
    label-tag="name"
    @select="createNew"
  />
</template>

<style lang="sass" scoped></style>
