import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex items-center justify-between mb-4" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "text-dark-white" }
const _hoisted_7 = { class: "grid grid-rows-3 grid-cols-2 grid-flow-col gap-4" }
const _hoisted_8 = { class: "grid grid-flow-col grid-rows-3" }

import { computed, ref } from 'vue'
import { boolean, object, string, array, number } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FieldArray, useFieldArray, useForm } from 'vee-validate'
import { IBrokerAccount, ICashFlow } from '@/entityes/invoice/cfr/cfr.type'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import usePolitic from '@/use/usePolitic'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import Checkbox from '@/07-Shared/ui/Checkbox.vue'
import { useCfrRepository } from '@/entityes/invoice/cfr/cfr.repository'

interface IEmits {
  (e: 'save', value: IBrokerAccount): void
  (e: 'update', value: IBrokerAccount): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'EditorBrokerAccount',
  emits: ["save", "update"],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit
const visible = ref(false)
const currentBrokerAccount = ref<IBrokerAccount | undefined>(undefined)
const { isAdmin } = usePolitic()
const { showBrokerAccount } = useCfrRepository()
const schema = toTypedSchema(
  object({
    account_number: string().required(),
    account_type: string().required(),
    broker_oksm_code: string().required(),
    broker_tax_payer_number: string(),
    broker_swift: string().test('show rule', 'Поле является обязательным', (value, context) => {
      const type = context.from?.[0].value.account_type
      if (type === 'bank') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    opening_account_date: string().required(),
    closed_account_date: string(),
    common_account: boolean(),
    broker_name: string().required(),
    broker_address: string().required(),
    cash_flows: array()
      .ensure()
      .of(
        object({
          addition: number(),
          at_begin: number(),
          at_end: number(),
          cash_flow_type: string(),
          currency_code: number(),
          subtraction: number(),
          other_asset_type: object({
            cash: boolean(),
            securities: boolean(),
            financial_derivatives: boolean(),
            participatory_interest_without_entity: boolean(),
            participatory_interest: boolean(),
            rights_of_claim: boolean(),
            other: boolean(),
          }),
        })
      ),
  })
)
const initial = computed(() => {
  return {
    account_number: currentBrokerAccount.value?.account_number || '',
    account_type: currentBrokerAccount.value?.account_type || 'bank',
    broker_oksm_code: currentBrokerAccount.value?.broker_oksm_code || '',
    broker_tax_payer_number: currentBrokerAccount.value?.broker_tax_payer_number || '',
    broker_swift: currentBrokerAccount.value?.broker_swift || '',
    opening_account_date: currentBrokerAccount.value?.opening_account_date || '',
    closed_account_date: currentBrokerAccount.value?.closed_account_date || '',
    broker_name: currentBrokerAccount.value?.broker_name || '',
    broker_address: currentBrokerAccount.value?.broker_address || '',
    common_account: currentBrokerAccount.value?.common_account || false,
    cash_flows: cashFlowsInitial.value,
  }
})
const cashFlowsInitial = computed(() => {
  if (!!currentBrokerAccount.value) {
    return [...currentBrokerAccount.value?.cash_flows]
  } else {
    return [
      {
        addition: 0.0,
        at_begin: 0.0,
        at_end: 0.0,
        cash_flow_type: 'cash',
        currency_code: 840,
        subtraction: 0.0,
        other_asset_type: {
          cash: true,
          securities: false,
          financial_derivatives: false,
          participatory_interest_without_entity: false,
          participatory_interest: false,
          rights_of_claim: false,
          other: false,
        },
      },
    ]
  }
})
const { handleSubmit, values, setValues, validate, resetForm } = useForm({
  validationSchema: schema,
  initialValues: { ...initial.value },
})
const { fields, push, remove } = useFieldArray<ICashFlow>('cash_flows')
const accountTypeList = [
  {
    label: 'Банк',
    value: 'bank',
  },
  {
    label: 'Иная организация финансового рынка',
    value: 'broker',
  },
]
const loading = ref(false)

function replaceNullsWithEmptyStrings(obj: any): any {
  for (const key in obj) {
    if (obj[key] === null) {
      if (Object.keys(initial.value).includes(key)) {
        obj[key] = ''
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceNullsWithEmptyStrings(obj[key])
    }
  }
  return obj
}
function open(cfrId: string | number, accountId: string | number | undefined) {
  currentBrokerAccount.value = undefined
  if (!!accountId) {
    loading.value = true
    showBrokerAccount(cfrId, accountId)
      .then((res: IBrokerAccount) => {
        currentBrokerAccount.value = replaceNullsWithEmptyStrings({ ...res })
        setValues(currentBrokerAccount.value as IBrokerAccount)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        visible.value = true
        loading.value = false
      })
  } else {
    resetForm()
    visible.value = true
    loading.value = false
  }
}

function close() {
  visible.value = false
}

const confirm = handleSubmit((val) => {
  loading.value = true
  const res = Object.keys(val).reduce((acc, key) => {
    if (val[key] !== '') {
      acc[key] = val[key]
    }
    return acc
  }, {})
  if (!!currentBrokerAccount.value) {
    emit('update', Object.assign(res, currentBrokerAccount.value?.id) as IBrokerAccount)
  } else {
    emit('save', res as IBrokerAccount)
  }
})

__expose({ open, close })

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: visible.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((visible).value = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(currentBrokerAccount.value ? 'Редактировать счет' : 'Создать счет'), 1),
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_el_form, {
          class: "font-color--gray",
          onSubmit: _withModifiers(_unref(confirm), ["prevent"]),
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(FormItem, {
                class: "mb-4",
                label: "Название организации",
                name: "broker_name"
              }, {
                default: _withCtx(({ value, onBlur, onInput }) => [
                  _createVNode(_component_el_input, {
                    "model-value": value,
                    type: "text",
                    name: "broker_name",
                    onBlur: onBlur,
                    onInput: onInput
                  }, null, 8, ["model-value", "onBlur", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(FormItem, {
                class: "mb-4",
                label: "Адрес организации",
                name: "broker_address"
              }, {
                default: _withCtx(({ value, onBlur, onInput }) => [
                  _createVNode(_component_el_input, {
                    "model-value": value,
                    type: "text",
                    name: "broker_address",
                    onBlur: onBlur,
                    onInput: onInput
                  }, null, 8, ["model-value", "onBlur", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(FormItem, {
                class: "mb-4",
                label: "Номер счета",
                name: "account_number"
              }, {
                default: _withCtx(({ value, onBlur, onInput }) => [
                  _createVNode(_component_el_input, {
                    "model-value": value,
                    type: "text",
                    name: "account_number",
                    onBlur: onBlur,
                    onInput: onInput
                  }, null, 8, ["model-value", "onBlur", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(FormItem, {
                class: "mb-4",
                label: "Тип организации",
                name: "account_type"
              }, {
                default: _withCtx(({ value, onInput, onBlur }) => [
                  _createVNode(_component_el_select, {
                    "model-value": value,
                    "onUpdate:modelValue": onInput,
                    filterable: "",
                    placeholder: "Выберите тип организации",
                    onBlur: onBlur,
                    name: "account_type"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(accountTypeList, (item) => {
                        return _createVNode(_component_el_option, {
                          key: item.value,
                          value: item.value,
                          label: item.label
                        }, null, 8, ["value", "label"])
                      }), 64))
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                ]),
                _: 1
              }),
              (_unref(values).account_type === 'bank')
                ? (_openBlock(), _createBlock(FormItem, {
                    key: 0,
                    class: "mb-4",
                    label: "Swift или БИК",
                    name: "broker_swift"
                  }, {
                    default: _withCtx(({ value, onBlur, onInput }) => [
                      _createVNode(_component_el_input, {
                        "model-value": value,
                        type: "text",
                        name: "broker_swift",
                        onBlur: onBlur,
                        onInput: onInput
                      }, null, 8, ["model-value", "onBlur", "onInput"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(FormItem, {
                label: "Номер налогоплательщика",
                name: "broker_tax_payer_number"
              }, {
                default: _withCtx(({ value, onBlur, onInput }) => [
                  _createVNode(_component_el_input, {
                    "model-value": value,
                    type: "text",
                    name: "broker_tax_payer_number",
                    onBlur: onBlur,
                    onInput: onInput
                  }, null, 8, ["model-value", "onBlur", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(FormItem, {
                class: "mb-4",
                label: "Код валюты",
                name: "broker_oksm_code"
              }, {
                default: _withCtx(({ value, onInput, onBlur }) => [
                  _createVNode(_component_el_select, {
                    "model-value": value,
                    "onUpdate:modelValue": onInput,
                    filterable: "",
                    placeholder: "Выберите код страны",
                    onBlur: onBlur
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.shortname
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(FormItem, {
                  class: "mr-4",
                  label: "Дата открытия счета",
                  name: "opening_account_date"
                }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_date_picker, _mergeProps(field, {
                      "model-value": value,
                      type: "date",
                      placeholder: "Выберите дату",
                      format: "DD.MM.YYYY",
                      name: "opening_account_date"
                    }), null, 16, ["model-value"])
                  ]),
                  _: 1
                }),
                _createVNode(FormItem, {
                  label: "Дата закрытия счета",
                  name: "closed_account_date"
                }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_date_picker, _mergeProps(field, {
                      "model-value": value,
                      type: "date",
                      placeholder: "Выберите дату",
                      format: "DD.MM.YYYY"
                    }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(FormItem, { name: "common_account" }, {
                default: _withCtx(({ value, field }) => [
                  _createVNode(Checkbox, _mergeProps({ value: value }, field), {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Совместный счет ")
                    ])),
                    _: 2
                  }, 1040, ["value"])
                ]),
                _: 1
              })
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "el-divider-purple my-8" }, null, -1)),
            (_unref(isAdmin))
              ? (_openBlock(), _createBlock(_unref(FieldArray), {
                  key: 0,
                  class: "mb-4",
                  name: "cash_flows"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fields), (cash, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: cash.key
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("h3", _hoisted_6, _toDisplayString(idx + 1) + " " + _toDisplayString(cash.value.other_asset_type.cash || cash.value.cash_flow_type === 'cash'
                      ? 'Движение денег'
                      : 'Движение иных финансовых активов'), 1)
                          ]),
                          _createVNode(_component_el_button_group, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                size: "small",
                                disabled: _unref(fields).length === 1,
                                icon: _unref(RemoveFilled),
                                type: "danger",
                                onClick: ($event: any) => (_unref(remove)(idx))
                              }, null, 8, ["disabled", "icon", "onClick"]),
                              _createVNode(_component_el_button, {
                                icon: _unref(CirclePlusFilled),
                                size: "small",
                                type: "primary",
                                onClick: _cache[0] || (_cache[0] = 
                    () =>
                      _unref(push)({
                        addition: 0.0,
                        at_begin: 0.0,
                        at_end: 0.0,
                        cash_flow_type: 'cash',
                        currency_code: 840,
                        subtraction: 0.0,
                        other_asset_type: {
                          cash: true,
                          securities: false,
                          financial_derivatives: false,
                          participatory_interest_without_entity: false,
                          participatory_interest: false,
                          rights_of_claim: false,
                          other: false,
                        },
                      })
                  )
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(FormItem, {
                            label: "Зачислено",
                            class: "mb-2",
                            name: `cash_flows[${idx}].addition`
                          }, {
                            default: _withCtx(({ value, onInput, onBlur }) => [
                              _createVNode(_component_el_input, {
                                "onUpdate:modelValue": onInput,
                                "model-value": value,
                                onBlur: onBlur
                              }, null, 8, ["onUpdate:modelValue", "model-value", "onBlur"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            label: "Сумма на начало периода",
                            class: "mb-2",
                            name: `cash_flows[${idx}].at_begin`
                          }, {
                            default: _withCtx(({ value, onInput, onBlur }) => [
                              _createVNode(_component_el_input, {
                                "onUpdate:modelValue": onInput,
                                "model-value": value,
                                onBlur: onBlur
                              }, null, 8, ["onUpdate:modelValue", "model-value", "onBlur"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-4",
                            label: "Код страны",
                            name: `cash_flows[${idx}].currency_code`
                          }, {
                            default: _withCtx(({ value, onInput, onBlur }) => [
                              _createVNode(_component_el_select, {
                                "model-value": value,
                                "onUpdate:modelValue": onInput,
                                filterable: "",
                                placeholder: "Выберите валюту",
                                onBlur: onBlur
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.code,
                                      value: item.code,
                                      label: item.shortname
                                    }, null, 8, ["value", "label"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            label: "Списано",
                            class: "mb-2",
                            name: `cash_flows[${idx}].subtraction`
                          }, {
                            default: _withCtx(({ value, onInput, onBlur }) => [
                              _createVNode(_component_el_input, {
                                "onUpdate:modelValue": onInput,
                                "model-value": value,
                                onBlur: onBlur
                              }, null, 8, ["onUpdate:modelValue", "model-value", "onBlur"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            label: "Сумма на конец периода",
                            class: "mb-2",
                            name: `cash_flows[${idx}].at_end`
                          }, {
                            default: _withCtx(({ value, onInput, onBlur }) => [
                              _createVNode(_component_el_input, {
                                "onUpdate:modelValue": onInput,
                                "model-value": value,
                                onBlur: onBlur
                              }, null, 8, ["onUpdate:modelValue", "model-value", "onBlur"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            label: "Тип",
                            name: `cash_flows[${idx}].cash_flow_type`
                          }, {
                            default: _withCtx(({ value, onInput, onBlur }) => [
                              _createVNode(_component_el_select, {
                                "model-value": value,
                                "onUpdate:modelValue": onInput,
                                filterable: "",
                                placeholder: "Выберите тип",
                                onBlur: onBlur
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['cash', 'other'], (item) => {
                                    return _createVNode(_component_el_option, {
                                      key: item,
                                      value: item,
                                      label: item
                                    }, null, 8, ["value", "label"])
                                  }), 64))
                                ]),
                                _: 2
                              }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                            ]),
                            _: 2
                          }, 1032, ["name"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.cash`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("cash")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.securities`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("securities")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.financial_derivatives`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode("financial_derivatives")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.participatory_interest_without_entity`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("participatory_interest_without_entity")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.participatory_interest`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("participatory_interest")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.rights_of_claim`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode("rights_of_claim")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createVNode(FormItem, {
                            class: "mb-2",
                            name: `cash_flows[${idx}].other_asset_type.other`
                          }, {
                            default: _withCtx(({ value, field }) => [
                              _createVNode(Checkbox, _mergeProps({
                                value: value,
                                ref_for: true
                              }, field), {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode("other")
                                ])),
                                _: 2
                              }, 1040, ["value"])
                            ]),
                            _: 2
                          }, 1032, ["name"])
                        ]),
                        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "el-divider-purple my-8" }, null, -1))
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_button, {
              loading: loading.value,
              "native-type": "submit",
              type: "primary"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Сохранить")
              ])),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ])), [
        [_directive_loading, loading.value]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})