import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { class: "flex items-center justify-between mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "step-box"
}
const _hoisted_4 = { class: "font-weight--extra p-4" }
const _hoisted_5 = { class: "grid grid-rows-* grid-cols-3 gap-4 p-4" }
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = { class: "font-weight--extra" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "font-weight--extra" }
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "font-weight--extra" }
const _hoisted_12 = { class: "mb-2" }
const _hoisted_13 = { class: "font-weight--extra" }
const _hoisted_14 = { class: "mb-4" }
const _hoisted_15 = { class: "font-weight--extra" }
const _hoisted_16 = { class: "bg-gray p-4" }

import { useCfrRepository } from '@/entityes/invoice/cfr/cfr.repository'
import { computed, ref } from 'vue'
import EditorBrokerAccount from '@/04-Widgets/CfrWidget/ui/EditorBrokerAccount.vue'
import { IBrokerAccount, IEditBrokerAccount } from '@/entityes/invoice/cfr/cfr.type'
import { useCfrStore } from '@/entityes/invoice/cfr/cfr.store'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, UploadFilled } from '@element-plus/icons-vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { IInvoice } from '@/entityes/invoice/invoice.type'

interface IProps {
  invoiceId: string | number
  good_id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AddCfrAccount',
  props: {
    invoiceId: {},
    good_id: {}
  },
  setup(__props: any) {

const props = __props
const { getBrokerAccounts, createBrokerAccount, destroyBrokerAccount, updateBrokerAccount, switchCfrStep } =
  useCfrRepository()
const cfrStore = useCfrStore()
const loading = ref(false)
const editor = ref<IEditBrokerAccount | null>(null)
const brokerNames = computed(() => {
  if (!!cfrStore.brokerAccounts) {
    return [...new Set(cfrStore.brokerAccounts.map((i) => i.broker_name))]
  }
})
const active = ref('')
const invoiceStore = useInvoiceStore()
const stepper = useStepper()
const { getInvoice } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()

function openEditor(id?: string | number) {
  if (!!id) {
    editor.value?.open(props.good_id, id)
  } else {
    editor.value?.open()
  }
}

function saveBrokerAccount(val) {
  loading.value = true
  createBrokerAccount(props.good_id, val)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'создана запись о брокерском аккаунте',
        type: 'success',
      })
      getIndexBrokerAccounts()
      editor.value?.close()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `создания записи о брокерском аккаунте | ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function deleteAccount(id: string | number) {
  loading.value = true

  destroyBrokerAccount(props.good_id, id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'удалена запись о брокерском аккаунте',
        type: 'success',
      })
      getIndexBrokerAccounts()
      editor.value?.close()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления записи о брокерском аккаунте | ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      loading.value = false
      editor.value?.close()
    })
}
function updateAccount(val: IBrokerAccount) {
  loading.value = true
  updateBrokerAccount(props.good_id, val as IBrokerAccount)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'обновлена запись о брокерском аккаунте',
        type: 'success',
      })
      getIndexBrokerAccounts()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `обновления записи о брокерском аккаунте | ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      loading.value = false
      editor.value?.close()
    })
}
function getIndexBrokerAccounts() {
  getBrokerAccounts(props.good_id).finally(() => (loading.value = false))
}
function accountsWithBrokerNames(name: string) {
  if (!!cfrStore.brokerAccounts) {
    return cfrStore.brokerAccounts.filter((i) => i.broker_name === name) as IBrokerAccount[]
  }
}
function getCountryName(oksmCode: string) {
  return useCountryDescriber().find((i) => Number(i.code) === Number(oksmCode))?.shortname
}
function spliceStep() {
  switchCfrStep(props.good_id, { step: ['Add_account'] })
    .then(() => {
      getInvoice(props.invoiceId).then((res: IInvoice) => {
        initAsync(res).then((res) => {
          stepper.selectStep(res)
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса перехода на следующий шаг ${err}`,
      })
    })
    .finally(() => (loading.value = false))
}
getIndexBrokerAccounts()

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "mb-4" }, "Отчет о движении средств", -1)),
      _createVNode(_component_el_button, {
        icon: _unref(CirclePlusFilled),
        class: "mb-4",
        type: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (openEditor()))
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Добавить счет")
        ])),
        _: 1
      }, 8, ["icon"])
    ]),
    (!!_unref(cfrStore).brokerAccounts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(brokerNames.value, (broker) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_el_collapse, {
                modelValue: active.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((active).value = $event)),
                accordion: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_collapse_item, null, {
                    title: _withCtx(() => [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(broker), 1)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsWithBrokerNames(broker), (account) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: account.id,
                            class: "bg-gray p-4 border-2 dark:border-dark-gray border-light-white bg-light-gray dark:bg-dark-dark_gray rounded-xl"
                          }, [
                            _createElementVNode("div", null, [
                              _createElementVNode("p", _hoisted_6, [
                                _cache[4] || (_cache[4] = _createTextVNode(" Номер счета: ")),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(account.account_number), 1)
                              ]),
                              _createElementVNode("p", _hoisted_8, [
                                _cache[5] || (_cache[5] = _createTextVNode(" Общий счет: ")),
                                _createElementVNode("span", _hoisted_9, _toDisplayString(account.common_account ? 'да' : 'нет'), 1)
                              ]),
                              _createElementVNode("p", _hoisted_10, [
                                _cache[6] || (_cache[6] = _createTextVNode(" Дата открытия: ")),
                                _createElementVNode("span", _hoisted_11, _toDisplayString(account.opening_account_date?.split('T')[0]), 1)
                              ]),
                              _createElementVNode("p", _hoisted_12, [
                                _cache[7] || (_cache[7] = _createTextVNode(" Дата закрытия: ")),
                                _createElementVNode("span", _hoisted_13, _toDisplayString(account.closed_account_date ? account.closed_account_date?.split('T')[0] : 'счет открыт'), 1)
                              ]),
                              _createElementVNode("p", _hoisted_14, [
                                _cache[8] || (_cache[8] = _createTextVNode(" Страна: ")),
                                _createElementVNode("span", _hoisted_15, _toDisplayString(getCountryName(account.broker_oksm_code)), 1)
                              ]),
                              _createVNode(_component_el_button_group, { size: "small" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    type: "primary",
                                    onClick: ($event: any) => (openEditor(account.id))
                                  }, {
                                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                                      _createTextVNode("Изменить")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  _createVNode(_component_el_popconfirm, {
                                    title: "Вы хотите удалить этот счет?",
                                    "confirm-button-text": "удалить",
                                    "cancel-button-text": "отменить",
                                    onConfirm: ($event: any) => (deleteAccount(account.id)),
                                    width: "300px"
                                  }, {
                                    reference: _withCtx(() => [
                                      _createVNode(_component_el_button, { type: "danger" }, {
                                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                                          _createTextVNode("Удалить")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onConfirm"])
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]))
          }), 256)),
          (_unref(cfrStore).brokerAccounts.length === 0)
            ? (_openBlock(), _createBlock(_component_el_empty, {
                key: 0,
                description: "У вас нет ни одного аккаунта"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_el_button, {
              disabled: _unref(cfrStore).brokerAccounts.length === 0,
              loading: loading.value,
              onClick: spliceStep,
              icon: _unref(UploadFilled),
              class: "button-main",
              type: "success"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" Отправить данные ")
              ])),
              _: 1
            }, 8, ["disabled", "loading", "icon"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(EditorBrokerAccount, {
      ref_key: "editor",
      ref: editor,
      onSave: saveBrokerAccount,
      onUpdate: updateAccount
    }, null, 512)
  ]))
}
}

})