import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import usePolitic from '@/use/usePolitic'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'

export function useStepper() {
  const router = useRouter()
  const invoiceStore = useInvoiceStore()
  const politics = usePolitic()
  const resolvedTms = ['Missing_resolved', 'Transfer_resolved', 'Short_resolved']
  const currentStep = ref<string | undefined>(undefined)

  const steps: Record<string, () => void> = {
    loadFile: () => {
      console.log('load file')

      router.push({
        name: 'file_in_invoice',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    prePayment: () => {
      console.log('pre payment')

      router.push({
        name: 'pre_add_deductions',
        replace: true,
      })
    },
    goodSelect: () => {
      console.log('good select')

      router.push({
        name: 'good_select',
        replace: true,
      })
    },
    tms: () => {
      console.log('tms')
      currentStep.value = 'tms'

      router.push({
        name: 'tms',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    personalData: () => {
      console.log('personalData')
      currentStep.value = 'personal_data'

      router.push({
        name: 'personal_data',
        replace: true,
      })
    },
    addIncomes: () => {
      console.log('addIncome')
      currentStep.value = 'add_incomes'

      router.push({
        name: 'add_incomes',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    deductions: () => {
      console.log('deductions')
      currentStep.value = 'deductions'

      router.push({
        name: 'deductions',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    cfr: () => {
      console.log('cfr')
      currentStep.value = 'cfr'

      router.push({
        name: 'cfr',
        replace: true,
        params: {
          good_id: 5523,
        },
      })
    },
    show: () => {
      console.log('show')
      currentStep.value = 'invoice show'

      router.push({
        name: 'invoice show',
        replace: true,
      })
    },
  }
  function selectStep(invoice: IInvoice, has_tms = false) {
    if (invoice.status?.invoice_status === 'payed') return afterPaymentStepper(invoice, has_tms)
    if (invoice.status?.invoice_status === 'new') return steps.loadFile()
    if (invoice.status?.invoice_status === 'file_uploaded') return steps.prePayment()
    if (invoice.status?.invoice_status === 'pre_add_services_selected') return steps.goodSelect()
    // return steps.show()
  }
  function checkingForTms(actions: DeclarationAction[]) {
    if (!actions) return false
    return actions.some((item) => resolvedTms.includes(item))
  }
  function checkingForTmsFromInvoice(actions: IInvoice['tms']) {
    if (!actions) return false
    return Object.values(actions).some((item) => item)
  }
  function afterPaymentStepper(invoice: IInvoice, has_tms = false) {
    if (has_tms && !politics.isAdmin.value) return steps.tms()
    if (!politics.isAdmin.value) {
      if (has_tms) return steps.tms()
      if (Object.keys(invoice.status.actions_for_goods).includes('Declaration')) {
        if (invoice.status.actions_for_goods.Declaration.includes('Personal_data')) return steps.personalData()
        if (invoice.status.actions_for_goods.Declaration.includes('Add_income')) return steps.addIncomes()
        if (invoice.status.actions_for_goods.Declaration.includes('Deduction')) return steps.deductions()
        if (Object.keys(invoice.status.actions_for_goods).includes('Cfr')) {
          if (invoice.status.actions_for_goods.Cfr.length > 0) {
            return steps.cfr()
          }
        }
        if (invoice.status.actions_for_goods.Declaration.every((item) => resolvedTms.includes(item)))
          return steps.show()
      }
      if (
        Object.keys(invoice.status.actions_for_goods).includes('Cfr') &&
        !Object.keys(invoice.status.actions_for_goods).includes('Declaration')
      ) {
        if (Object.keys(invoice.personal_data as Record<string, any>).length === 0) {
          return steps.personalData()
        }
        if (invoice.status.actions_for_goods.Cfr.length > 0) {
          return steps.cfr()
        } else if (!invoice.status.actions_for_goods.Cfr.includes('Add_account')) {
          return steps.show()
        }
      }
    }
  }

  function setCurrentStep(invoice: IInvoice, has_tms = false) {
    if (has_tms) {
      currentStep.value = 'tms'
      return
    }
    if (Object.keys(invoice.status.actions_for_goods).includes('Declaration')) {
      if (invoice.status.actions_for_goods.Declaration.includes('Personal_data')) {
        currentStep.value = 'personal_data'
        return
      }
      if (invoice.status.actions_for_goods.Declaration.includes('Add_income')) {
        currentStep.value = 'add_incomes'
        return
      }
      if (invoice.status.actions_for_goods.Declaration.includes('Deduction')) {
        currentStep.value = 'deductions'
        return
      }
      if (Object.keys(invoice.status.actions_for_goods).includes('Cfr')) {
        if (invoice.status.actions_for_goods.Cfr.length > 0) {
          currentStep.value = 'cfr'
          return
        }
      }
      if (invoice.status.actions_for_goods.Declaration.every((item) => resolvedTms.includes(item))) {
        if (Object.keys(invoice.goods).includes('Cfr')) {
          if (!invoice.status.actions_for_goods?.Cfr.includes('Add_account')) {
            currentStep.value = 'invoice show'
            return
          } else {
            currentStep.value = 'cfr'
            return
          }
        } else {
          currentStep.value = 'invoice show'
          return
        }
      }
    }
    if (
      Object.keys(invoice.status.actions_for_goods).includes('Cfr') &&
      !Object.keys(invoice.status.actions_for_goods).includes('Declaration')
    ) {
      if (!invoice.status.actions_for_goods.Cfr.includes('Add_account')) {
        currentStep.value = 'invoice show'
        return
      }
      if (Object.keys(invoice.personal_data as Record<string, any>).length === 0) {
        currentStep.value = 'personal_data'
        return
      } else {
        currentStep.value = 'cfr'
        return
      }
    }
  }

  return {
    selectStep,
    checkingForTms,
    checkingForTmsFromInvoice,
    currentStep,
    setCurrentStep,
  }
}
